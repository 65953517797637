import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import SceneHandler from '../utils/SceneHandler';

export default function RepeatSlider({ scene, selectedFabricTab }) {
  const [sceneHandler, setSceneHandler] = React.useState(null);
  function onSliderChange(event) {
    event.preventDefault();
    let selectedMesh = selectedFabricTab;
    if (!selectedFabricTab && scene) {
      let clothMeshGroup = scene.world.getObjectByName("CLOTH_MODEL_GROUP");
      if (clothMeshGroup) {
        clothMeshGroup.children.map((child, index) => {
          if (index === 0) {
            selectedMesh = child.name;
          }
          // meshNames.push(child.name);
        });
      }
    }
    sceneHandler.handleSliderChange(event.target.value / 100, selectedMesh);
  }

  function handleReset() {
    sceneHandler.resetView();
  }
  React.useEffect(() => {
    setSceneHandler(new SceneHandler(scene))
  }, [])

  return (
    <>
      <Box sx={{ height: 250 }}>
        <Slider
          sx={{
            '& input[type="range"]': {
              WebkitAppearance: 'slider-vertical',
            },
          }}
          orientation="vertical"
          defaultValue={50}
          aria-label="TextureRepeat"
          valueLabelDisplay="auto"
          onChange={onSliderChange}
        />
      </Box>
      {/* <button variant="contained" color="primary" onClick={handleReset} sx={{ mt: 2 }}> */}
      {/*   Reset */}
      {/* </button> */}
    </>

  );
}
