import { ThemeProvider, createTheme } from '@mui/material/styles';
import Contact from './components/Contact';
import Visualizer from './components/Visualizer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';

import Box from '@mui/material/Box';
import { Footer } from './components/Footer';
import { NewHome } from './components/NewHome';
import { Navbar } from './components/Navbar';
import { AboutPage } from './components/AboutPage';
import { HomePage } from './components/Home';
import Hotjar from '@hotjar/browser';

const siteId = 5081419;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);


const theme = createTheme({
  palette: {
    primary: {
      main: '#2196f3',
    },
    secondary: {
      main: '#f50057',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Box>
          <Navbar />
          <Routes>
            <Route path="/" exact element={<NewHome />} />
            <Route path="/check" exact element={<HomePage />} />
            <Route path="/customize" exact element={<Visualizer height={"90vh"} />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" component={<Login />} />
          </Routes>
          <Footer />
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;
