import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
// import VolumeUp from '@mui/icons-material/VolumeUp';

const Input = styled(MuiInput)`
  width: 42px;
`;

export default function InputSlider({label,defaultValue,min,max,step, onSliderChange}) {
  const [value, setValue] = React.useState(defaultValue);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
    onSliderChange(value);
  };

  const handleInputChange = (event) => {
    setValue(event.target.value === '' ? 0 : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 5) {
      setValue(5);
    }
  };

  return (
    <Box>
      
      <Grid container spacing={2} alignItems="center">
        <Grid item>
        <Typography id="input-slider" gutterBottom>
        {label}
      </Typography>
        </Grid>
        <Grid item xs>
          <Slider
            value={typeof value === 'number' ? value : defaultValue}
            onChange={handleSliderChange}
            defaultValue={defaultValue}
            step={step}
            min={min}
            max={max}
            aria-labelledby="input-slider"
          />
        </Grid>
        <Grid item>
          <Input
            value={value}
            size="small"
            onChange={handleInputChange}
            onBlur={handleBlur}
            disabled={true}
          />
        </Grid>
      </Grid>
    </Box>
  );
}