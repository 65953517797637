import * as React from 'react';
import Box from '@mui/material/Box';
import SceneHandler from '../utils/SceneHandler';
import { catalogue } from '../utils/Catalogue';

import PopoverComponent from './PopoverComponent';
import PopoverComponentFabrics from './PopoverComponentFabrics';
import PopoverComponentOptions from './PopoverComponentOptions';
import { PopOverComponent } from './PopOver';
import { Switch } from '@mui/material';

export const SimpleBottomNavigation = ({ height, scene, sendTemplateDataToParent, sendFabricsDataToParent }) => {
  const [value, setValue] = React.useState(0);;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedTab, setSelectedTab] = React.useState(null);
  const [popoverData, setPopoverData] = React.useState({});
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  // const [selectedTemplateName, setSelectedTemplateName] = React.useState(null);
  // const [selectedMeshName, setSelectedMeshName] = React.useState(null);
  // const [loadedMeshNamesList, setLoadedMeshNamesList] = React.useState([]);
  let sceneHandler = null;
  React.useEffect(() => {
    sceneHandler = new SceneHandler(scene)
  }, [])
  // const getLoadedMeshNames = (data) =>{
  //   // setLoadedMeshNamesList(data);
  // }
  const handleTabClick = (event, tabId) => {
    setAnchorEl(event.currentTarget);
    setSelectedTab(tabId);
    if (tabId !== "options") {
      setPopoverData(catalogue[tabId]);
    }
  };

  const closePopover = () => {
    setAnchorEl(null);
  };
  return (
    <Box>
      {/* <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}> */}
      <div>
        {/* <BottomNavigation */}
        {/*   showLabels */}
        {/*   value={value} */}
        {/*   onChange={(event, newValue) => { */}
        {/*     setValue(newValue); */}
        {/*   }} */}
        {/**/}
        {/* > */}
        <div className='grid grid-cols-2 lg:flex  lg:items-start lg:flex-col space-y-4'>


          <div onClick={(event) => handleTabClick(event, "options")} className='flex items-center  justify-center gap-4'>
            <div className='border border-[#C5D9F4] rounded-[10px] w-[74px] h-[74px] flex items-center justify-center'>
              <img src={"/option1.png"} />
            </div>
            <h4 className='lg:text-3xl'>Options</h4>
          </div>

          <div onClick={(event) => handleTabClick(event, "templates")} className='flex items-center  justify-center gap-4'>
            <div className='border border-[#C5D9F4] rounded-[10px] w-[74px] h-[74px] flex items-center justify-center'>
              <img src={"/option2.png"} />
            </div>
            <h4 className='lg:text-3xl'>Templates</h4>
          </div>

          <div onClick={(event) => handleTabClick(event, "fabrics")} className='flex items-center  justify-center gap-4'>
            <div className='border border-[#C5D9F4] rounded-[10px] w-[74px] h-[74px] flex items-center justify-center'>
              <img src={"/option3.png"} />
            </div>
            <h4 className='lg:text-3xl'>Fabrics</h4>
          </div>

          <div className='pl-4 lg:pl-0'>
            <PopOverComponent heading={"Download"} link={"./option4.png"}>
              <div className="space-y-4">
                <p className="text-xl">Download Setting</p>
                <div className="flex items-center gap-4">
                  <input type="checkbox" />
                  <p className="text-[#787878]">Sharpen Image</p>
                </div>
                <div className="flex items-center gap-4">
                  <input type="checkbox" />
                  <p className="text-[#787878]">
                    Download with Template Resolution
                  </p>
                </div>
                <div className="space-y-1">
                  <p className="text-[#787878] text-sm">Width</p>
                  <input className="border border-[#D9D9D9] px-[15px] py-1 rounded-[5px] w-full outline-none" />
                </div>
                <div className="space-y-1">
                  <p className="text-[#787878] text-sm">Height</p>
                  <input className="border border-[#D9D9D9] px-[15px] py-1 w-full rounded-[5px] outline-none" />
                </div>

                <div className="flex items-center ">
                  <Switch
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <p className="text-[#787878] text-sm">Bulk Download</p>
                </div>

                {checked ? (
                  <div>
                    <div className="flex items-center ">
                      <Switch inputProps={{ "aria-label": "controlled" }} />
                      <p className="text-[#787878] text-sm">Outfits Download</p>
                    </div>
                    <div>
                      <p className="text-sm text-[#787878]">
                        Bulk download will not work on Safari browsers and iOS
                        devices.
                      </p>
                    </div>
                    <div className="flex items-center justify-between my-4 first:bg-[#0072B5] first:text-white">
                      <button className="hover:bg-[#0072B5] text-[#787878] bg-[#F8F8F8] hover:text-white py-2 px-4 rounded-full">
                        Default
                      </button>
                      <button className="hover:bg-[#0072B5] text-[#787878] bg-[#F8F8F8] hover:text-white py-2 px-4 rounded-full">
                        All Slotes
                      </button>
                      <button className="hover:bg-[#0072B5] text-[#787878] bg-[#F8F8F8] hover:text-white py-2 px-4 rounded-full">
                        Custom Slotes
                      </button>
                    </div>
                    <div className="space-y-4">
                      <div className="flex items-center">
                        <Switch inputProps={{ "aria-label": "controlled" }} />
                        <div className="flex items-center gap-2 ">
                          <div className="relative">
                            <h3 className="text-xl text-[#787878] mt-2 mr-4">
                              Template Tag
                            </h3>
                            <h4 className="absolute text-red-800 right-0 top-0 text-xs">
                              New
                            </h4>
                          </div>
                          <p className="text-xs text-[#787878]">
                            (templates in the tag will only be considered for
                            bulk)
                          </p>
                        </div>
                      </div>

                      <div className="flex items-center">
                        <Switch inputProps={{ "aria-label": "controlled" }} />
                        <div className="flex items-center gap-2 ">
                          <div className="relative">
                            <h3 className="text-xl text-[#787878] mt-2 mr-4">
                              Garment Tag
                            </h3>
                            <h4 className="absolute text-red-800 right-0 top-0 text-xs">
                              New
                            </h4>
                          </div>
                          <p className="text-xs text-[#787878]">
                            (Garment in the tag will only be considered for
                            bulk)
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-center my-4">
                      <p className="text-xs text-[#787878] flex-1">
                        No garments in bulk. Please go to <br /> the Gallery
                        page to add to the bulk.
                      </p>
                      <p className="text-xs text-[#787878] flex-1">
                        No garments in bulk. Please go to <br /> the Gallery
                        page to add to the bulk.
                      </p>
                    </div>
                    <div className="flex items-center gap-2">
                      <input type="checkbox" />
                      <p className="text-xs">
                        Use fabric's default magnification
                      </p>
                    </div>
                    <div className="my-4 space-y-2">
                      <h3 className="font-bold text-xs">0 magnifications</h3>
                      <input className="border border-[#D9D9D9] p-1" />
                    </div>
                  </div>
                ) : null}
              </div>
            </PopOverComponent>

          </div>

          <div className='pl-8 lg:pl-0 pb-8 lg:pb-0'>
            <PopOverComponent heading={"Share"} link={"./option5.png"}>
              <div className="space-y-4">
                <div className="flex items-center justify-start gap-4 ">
                  <input type="checkbox" />
                  <p>Share only Image</p>
                </div>
                <div className="flex items-center justify-center gap-4 border border-black py-2 rounded-[5px]">
                  <img src="./facebook.png" className="w-[30px] h-[30px]" />
                  <p className="font-[16px]">Facebook</p>
                </div>
                <div className="flex items-center justify-center gap-4 border border-black py-2 rounded-[5px]">
                  <img src="./whatsapp.png" className="w-[30px] h-[30px]" />
                  <p className="font-[16px]">WhatsApp</p>
                </div>
                <div className="flex items-center justify-center gap-4 border border-black py-2 rounded-[5px]">
                  <img src="./linkdin.png" className="w-[30px] h-[30px]" />
                  <p className="font-[16px]">Linkedln</p>
                </div>

                <div>
                  <div className="flex items-center border border-black rounded-[8px]">
                    <input
                      value={
                        "https://fabric.tri3d.in/?pose_name=a1_all_set198_shirt"
                      }
                      placeholder="Enter url"
                      className="py-2 pl-2 outline-none w-[80%] text-sm"
                    />
                    <button className="bg-[#ECECEC] text-black w-[20%] px-3 py-2">
                      Copy Link
                    </button>
                  </div>
                </div>
              </div>
            </PopOverComponent>

          </div>
          {/* <BottomNavigationAction label="Options" onClick={(event) => handleTabClick(event, 'options')} icon={<TuneIcon />} /> */}
          {/* <BottomNavigationAction label="Templates" onClick={(event) => handleTabClick(event, 'templates')} icon={<CheckroomIcon />} /> */}
          {/* <BottomNavigationAction label="Fabrics" onClick={(event) => handleTabClick(event, 'fabrics')} icon={<StyleIcon />} /> */}
        </div>
        {/* </BottomNavigation> */}
      </div>
      {
        selectedTab === "options" ? <PopoverComponentOptions isOpen={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={closePopover}
          scene={scene}></PopoverComponentOptions> :
          <PopoverComponentOptions isOpen={false}
            anchorEl={anchorEl}
            onClose={closePopover}
            scene={scene}></PopoverComponentOptions>
      }
      {
        selectedTab === "templates" ?
          <PopoverComponent isOpen={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={closePopover}
            data={popoverData}
            scene={scene}
            sendDataToParent={sendTemplateDataToParent}
          // fetchLoadedMeshNames = {getLoadedMeshNames}
          >
          </PopoverComponent> :
          <PopoverComponent isOpen={false}
            anchorEl={anchorEl}
            onClose={closePopover}
            data={popoverData}
            scene={scene}
            sendDataToParent={sendTemplateDataToParent}
          // fetchLoadedMeshNames = {getLoadedMeshNames}
          ></PopoverComponent>
      }{
        selectedTab === "fabrics" ?
          <PopoverComponentFabrics isOpen={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={closePopover}
            data={popoverData}
            scene={scene}
            sendDataToParent={sendFabricsDataToParent}
          // loadedMeshNamesList = {loadedMeshNamesList}
          ></PopoverComponentFabrics> :
          <PopoverComponentFabrics isOpen={false}
            anchorEl={anchorEl}
            onClose={closePopover}
            data={popoverData}
            scene={scene}
            sendDataToParent={sendFabricsDataToParent}
          // loadedMeshNamesList = {loadedMeshNamesList}
          >
          </PopoverComponentFabrics>
      }
    </Box>
  );
}

