import SceneController from "./SceneController";

class SceneHandler {
    constructor(scene) {
        this.scene = scene.world;
        this.sceneController = new SceneController();
      }
    
      resetView(){
        this.sceneController.resetView(this.scene)
      }

      addglTF(filePaths){
        this.sceneController.disposeScene(this.scene);
        this.sceneController.loadGLTF(filePaths, this.scene)
      }

      handleSliderChange(repeatValue,meshName){
        this.sceneController.updateRepeatValues(repeatValue, this.scene,meshName);
      }

      handleOffsetChange(x, y,meshName){
        this.sceneController.updateOffsetValues(x, y, this.scene,meshName)
      }

      fabricChange(url,meshName){
        this.sceneController.updateFabric(url, this.scene,meshName);
      }

      shadowChange(opacity){
        this.sceneController.updateShadows(opacity, this.scene);
      }
  }
  
  export default SceneHandler;
  