import { Box, Button } from '@mui/material';
import styled from "styled-components"
import { motion } from "framer-motion"


const BigHeading = styled.h5`
font-family: 'Jomolhari';
font-style: normal;
font-weight: 400;
font-size: 120px;
line-height: 115px;
color: #ffffff;
`

const BigHeading2 = styled.h5`
font-family: 'Jomolhari';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 35px;
/* or 146% */

color: #000000;


`

const BigHeading3 = styled.h6`
font-family: 'Jomolhari';
font-style: normal;
font-weight: 400;
font-size: 60px;
line-height: 80px;
/* or 133% */

color: #00000;

`

const BigHeading3White = styled(BigHeading3)`
color: #ffffff;

`

const FabricSupplierStyled = styled.h5`
border: 1px solid #A6A6A6;
border-radius: 10px;

`

const FabricSupplierStyled2 = styled.p`
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 24px;
color: #000000;
`


const FabirdCountryStyled = styled.p`
font-family: 'Jomolhari';
font-style: normal;
font-weight: 400;
font-size: 60px;
line-height: 35px;
/* or 58% */

color: #000000;


`

const NewLovojStyled = styled.h4`
font-family: 'Jomolhari';
font-style: normal;
font-weight: 400;
line-height: 115px;
color: #FFFFFF;
`

const NewLovojStyledBlue = styled(NewLovojStyled)`
color: #0072B5;

`
// const url = "https://d12hs8wunnl6k1.cloudfront.net/louoj3d"

export const NewHome = () => {
  // check
  return (

    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,

      }}
      transition={{ duration: 2 }}
    >

      <Box className="space-y-20">
        <Box className="h-[80vh] lg:h-[90vh]" position={"relative"}>
          {/* <video autoPlay muted loop className='absolute top-0 object-cover h-[90vh] z-0' src="./homeVideo.mov" /> */}
          <video autoPlay muted loop className='absolute top-0 object-cover w-full h-[85vh] lg:h-[90vh] z-0' src="
            https://d12hs8wunnl6k1.cloudfront.net/website/animation.mov
            " />
          <Box
            sx={
              {
                paddingTop: "33px"
              }
            }
            spacing={2}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}>
            <motion.div
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                scale: [1, 2, 2, 1, 1],
                rotate: [0, 0, 270, 270, 0],
                borderRadius: ["20%", "20%", "50%", "50%", "20%"],
              }}
              transition={{ duration: 3 }}
            >
              <img
                className='opacity-0'
                style={{
                  maxWidth: "1280px",
                  width: "100%"
                }}
                src="./homeBanner.png" />
            </motion.div>
          </Box>
          <div className="hidden lg:block absolute top-[20px] left-[100px]" >
            <BigHeading3White >3D Customized <br /> Bespoke Clothing</BigHeading3White>
          </div>

          <div className='hidden lg:block absolute top-[20px] right-[150px]'>
            <motion.h5
              initial={{
                color: "#000000"
              }}
              animate={{
                color: "#ffffff"
              }}
              className='text-3xl' >
              Transform and Modernize Your <br /> Bespoke Clothing Business with <br /> Louoj Designer App!
            </motion.h5>
          </div>
          <div
            className='
            hidden
            lg:block
            absolute
            top-[450px]
            left-[100px]
            '
          >
            <BigHeading
            >New <br />
              Louoj</BigHeading>
          </div>
          <div
            className='
            hidden
            lg:block
            absolute
            right-[200px]
            bottom-[100px]
            '
          >
            <Button
              sx={{
                borderRadius: "160px"
              }}
              size='large'
              variant="contained">GARMENT GENE</Button>

            {/* mobile home items */}
          </div>
          {/* mobile home items */}
          <h3 className='text-white lg:hidden absolute z-50 top-4 left-4 text-[24px]
            jomolhari-regular 
            '>3D Customized <br /> Bespoke Clothing</h3>
          <p className='text-white z-50 lg:hidden absolute top-28 left-4'>
            Transform and Modernize Your <br /> Bespoke Clothing Business with <br /> Louoj Designer App!
          </p>
          <h3
            className='
            lg:hidden
            text-white
            z-50
            absolute
            bottom-20
            left-4
            lg:text-[48px]
            text-[30px]
            '
          >New Lovoj</h3>
          <button className='lg:hidden bg-[#0072B5] text-white z-50 absolute bottom-4 text-[16px] px-6 py-4 rounded-full left-4'>
            GarmentGenie

          </button>


        </Box >
        {/* // TODO: second */}
        <div className="flex  justify-center items-center bg-[#f8f8f8]">
          <div className='grid px-4 lg:grid-cols-3 gap-10 container py-[96px]'>
            <div className='col-span-1 flex-col flex justify-center items-start'>
              <BigHeading2 className='text-black'>PEOPLE WHO WORK <br /> WITH US</BigHeading2>
              <BigHeading3>
                Louoj <br /> SaaS
              </BigHeading3>
            </div>
            <div className='col-span-1 flex justify-center items-center'>
              <img src='./banner2.png' alt="Banner" />
            </div>
            <div className='space-y-4 mt-10'>
              <FabricSupplierStyled className='col-span-1 h-[90px] px-2 justify-between flex items-center'>
                <FabricSupplierStyled2>Fabric Supplier</FabricSupplierStyled2>
                <img src='./plus.png' />
              </FabricSupplierStyled>

              <FabricSupplierStyled className='col-span-1 h-[90px] px-2 justify-between flex items-center'>
                <FabricSupplierStyled2>Bespoke Clothing Shops</FabricSupplierStyled2>
                <img src='./plus.png' />
              </FabricSupplierStyled>

              <FabricSupplierStyled className='col-span-1 h-[90px] px-2 justify-between flex items-center'>
                <FabricSupplierStyled2>Garment Factories</FabricSupplierStyled2>
                <img src='./plus.png' />
              </FabricSupplierStyled>

              <FabricSupplierStyled className='col-span-1 h-[90px] px-2 justify-between flex items-center'>
                <FabricSupplierStyled2>Accessories Suppliers</FabricSupplierStyled2>
                <img src='./plus.png' />
              </FabricSupplierStyled>

              <FabricSupplierStyled className='col-span-1 h-[90px] px-2 justify-between flex items-center'>
                <FabricSupplierStyled2>Bespoke Clothing Shops</FabricSupplierStyled2>
                <img src='./plus.png' />
              </FabricSupplierStyled>
            </div>

          </div>
        </div>
        {/* TODO: new movie */}
        <div
          className='
        container
        m-auto
        space-y-8
          px-4
          lg:px-0
        '>
          <div
            className='
        rounded-[30px]
        bg-[#242424] relative h-[708px]'
          >
            <div className='flex items-center justify-center
            h-full
            '>
              <NewLovojStyled className="text-[50px] lg:text-[100px]">New
              </NewLovojStyled>

              <NewLovojStyledBlue className='ml-2 text-[50px] lg:text-[100px]'>
                Louoj
              </NewLovojStyledBlue>
            </div>
            <div
              className='absolute
              left-[20%]
              lg:left-[43%]
            rounded-t-xl
            bottom-0
            w-[223px]
            bg-white
            h-[96px]
            m-auto
            flex
            items-center
            justify-center
            gap-4
            '>
              <div className='border border-[#0072B5] w-[56px] h-[56px] flex items-center justify-center rounded-full'>
                <img src='./play.png' />
              </div>
              <div className='w-[56px] h-[56px] bg-[#0072b5] rounded-full flex items-center justify-center'>
                <img src='./sound.png' />
              </div>
            </div>
          </div>
          <button className='w-full
          h-[80px]
          rounded-[170px]
          text-white
          bg-[#242424]
          '>Get Qoute</button>
        </div>

        {/* TODO: who we are */}
        <div className='flex
        items-center
        justify-center
        flex-col
        space-y-8
          px-4
        '>
          <h5 className='
          text-[30px]
          text-[#0072B5]
          '>WHO WE ARE</h5>
          <FabirdCountryStyled>Our Company</FabirdCountryStyled>
          <img
            style={{
              width: "1139px"
            }}
            // className='w-[1139px]'
            src='https://d12hs8wunnl6k1.cloudfront.net/website/Shop.png'
          // src='./shop.png' 
          />
          <p className='lg:w-[1127px] pb-8 m-auto text-center'>
            LOUOJ is World's 1st Bespoke Fashion Aggregator and SaaS for Fashion Industry. For the first time end-consumers can shop custom-made tailored clothing online from any designer/tailor/boutiques of their own choice replacing traditional mass produced readymade clothing. For the first time Buyer, Tailor and Fabric Supplier are brought together in one ecosystem.
          </p>
        </div>

      </Box>
    </motion.div>
  )
}






