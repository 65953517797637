import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ActionAreaCard from './ActionAreaCard';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import SceneHandler from '../utils/SceneHandler';
import { catalogue } from '../utils/Catalogue';

let sceneHandler = null;

const getUrlsForTemplate = (name) => {
  for (const templateCategory in catalogue.templates) {
    for (const obj of catalogue.templates[templateCategory]) {
      if (obj.name === name) {
        return {
          modelURL: obj.modelURL,
          shadowGLBURL: obj.shadowGLBURL,
          faceGLBURL: obj.faceGLBURL
        };
      }
    }
  }

  return null;
};

export default function PopoverComponent({ anchorEl, onClose, data, scene, isOpen, sendDataToParent, fetchLoadedMeshNames }) {

  const open = isOpen; //Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [value, setValue] = React.useState(0);
  const [cardDataList, setCardDataList] = React.useState(catalogue.templates["shirt"]);
  const [filteredDataList, setFilteredDataList] = React.useState(catalogue.templates["shirt"]);
  const [query, setQuery] = React.useState('');
  async function handleCardClickHandler(event) {
    const urlsObject = getUrlsForTemplate(event.target.alt)
    if (sceneHandler) {
      // const modelMeshNameList = await sceneHandler.addglTF(urlsObject);
      sceneHandler.addglTF(urlsObject);
      // fetchLoadedMeshNames(modelMeshNameList);
    }
  }

  React.useEffect(() => {
    sceneHandler = new SceneHandler(scene);

  }, [])

  React.useEffect(() => {
    setFilteredDataList(cardDataList);
  }, [cardDataList])
  const handleChange = (event, newValue) => {
    setValue(newValue);
    const currentKey = Object.keys(data)[newValue];
    setCardDataList(data[currentKey])
    sendDataToParent({ "selectedTemplateTab": newValue });
  };
  const handleSearchInputChange = (event) => {
    const searchQuery = event.target.value;
    setQuery(searchQuery);
    let dataFiltered = cardDataList.filter((data) => {
      return data.name.toLowerCase().includes(searchQuery.toLowerCase());
    })
    if (!searchQuery) {
      dataFiltered = cardDataList;
    }
    setFilteredDataList(dataFiltered);
  }
  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
  }));

  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className='p-4'>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
              onChange={handleSearchInputChange}
              value={query}
              autoFocus={true}
            />
          </Search>
          <Tabs
            className='scroll-auto max-w-[500px] my-4'
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {
              Object.keys(data).map((key) => {
                return <Tab label={key} />
              })
            }

          </Tabs>
          <Box style={{ display: "flex" }}>
            {filteredDataList.map((data) => {
              return <ActionAreaCard header={data.name} imageUrl={data.imgURL} handleCardClickHandler={handleCardClickHandler}></ActionAreaCard>
            })}
          </Box>
        </div>
      </Popover>
    </div>
  );
}
