import { createSlice, nanoid } from "@reduxjs/toolkit";
const initialState = {
  three: {
    scene: {},
    camera: {},
    renderer: {},
    selectedModel: {},
    selectedTexture: {},
  }
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setModel: (state, action) => {
      const newModel = action.payload;
      state.three.selectedModel = newModel;
    },
    setTexture: (state, action) => { },
    updateScene: (state, action) => {
      const newScene = action.payload;
      state.three.scene = newScene;
    },
    updateCamera: (state, action) => {
      const newCamera = action.payload;
      state.three.camera = newCamera;
    },
    updateRenderer: (state, action) => {
      const newRenderer = action.payload;
      state.three.renderer = newRenderer;
    }

  }
})

//export reducers
export const { setModel, setTexture, updateCamera, updateRenderer, updateScene } = appSlice.actions;
export default appSlice.reducer;
