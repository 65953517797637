import { Link } from "react-router-dom"
import { useLocation } from 'react-router-dom';
import { RiMenu3Line } from "react-icons/ri";
import { useState } from "react";
import { IoClose } from "react-icons/io5";



const NavbarLinks = ({ link, pathName }) => {
  const location = useLocation();
  const currentLocation = location.pathname;
  return (
    <div>
      <Link to={link}>
        <h4 className={`text-white text-lg ${currentLocation.toLowerCase() === pathName.toLowerCase() ? "text-[#0072B5]" : ""} hover:text-[#0072B5]`}>
          {pathName}
        </h4>
      </Link>
    </div >
  )
}



export const Navbar = () => {
  const [toggleNavbar, setToggleNavbar] = useState(false);

  const MobileNavLinks = ({ link, pathName }) => {
    return (
      <div>
        <Link to={link}>
          <h5 onClick={() => setToggleNavbar(false)} className="text-xl font-bold">{pathName}</h5>
        </Link>
      </div>

    )
  }
  return (
    <div className="bg-[#000000]/90
      h-[80px]
      lg:h-[96px]
      flex
      items-center
      justify-between
      px-4
      lg:px-20
      sticky
      top-0
      z-[40]
      ">
      <div>
        <Link to={"/"}>
          <img
            style={{
              height: "55px",
              width: "139px"
            }}
            src='./Logo.png' />
        </Link>
      </div>
      <div className="hidden 
        lg:flex 
        items-center 
        justify-between 
        gap-8">
        <NavbarLinks link="/" pathName="Home" />
        <NavbarLinks link="/about" pathName="About" />
        <NavbarLinks link="/about" pathName="Contact" />
        <NavbarLinks link="/customize" pathName="Customize" />
        <NavbarLinks link="/" pathName="Login" />
      </div>
      <div className="lg:hidden relative">
        <RiMenu3Line
          className="text-white font-bold"
          size={28}
          onClick={() => setToggleNavbar(!toggleNavbar)}
        />
        {toggleNavbar && (
          <div className="fixed 
            inset-0 
            bg-gray-400 
            bg-opacity-50 
            z-[99]">
            <div className="transform transition-transform ease-in-out duration-300 fixed inset-y-0 right-0 flex items-start justify-end bg-gray-200 p-6 w-80">
              <div className="flex items-center justify-center flex-col space-y-6">
                <IoClose
                  size={35}
                  onClick={() => setToggleNavbar(false)}
                />
                <div className="flex items-center justify-center  flex-col space-y-4">
                  <MobileNavLinks link={"/"} pathName="Home" />
                  <MobileNavLinks link={"/about"} pathName="About" />
                  <MobileNavLinks link={"/customize"} pathName="Customize" />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
