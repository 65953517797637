import styled from "styled-components"
import { motion } from "framer-motion"

const FabirdCountryStyled = styled.p`
font-family: 'Jomolhari';
font-style: normal;
font-weight: 400;
font-size: 60px;
line-height: 35px;
/* or 58% */

color: #000000;


`
export const AboutPage = () => {

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,

      }}
      transition={{ duration: 0.5 }}
    >

      <div className='flex 
        items-center
        justify-center
        flex-col
        space-y-8
        mt-10
        '>
        <h5 className='
          text-[30px]
          text-[#0072B5]
          '>WHO WE ARE</h5>
        <FabirdCountryStyled>Our Company</FabirdCountryStyled>
        <img
          style={{
            width: "1139px"
          }}
          className='w-[1139px]'
          src='https://d12hs8wunnl6k1.cloudfront.net/website/Shop.png' />
        <p className='lg:w-[1127px] px-4 lg:px-0 pb-8 m-auto text-center'>
          LOUOJ is World's 1st Bespoke Fashion Aggregator and SaaS for Fashion Industry. For the first time end-consumers can shop custom-made tailored clothing online from any designer/tailor/boutiques of their own choice replacing traditional mass produced readymade clothing. For the first time Buyer, Tailor and Fabric Supplier are brought together in one ecosystem.
        </p>
      </div>
    </motion.div>
  )
}
