import React, { useState, useRef, useEffect } from 'react';
import SceneHandler from '../utils/SceneHandler';

const Joystick = ({ scene, selectedFabricTab }) => {
  const canvasRef = useRef(null);
  const [position, setPosition] = useState({ x: 0.5, y: 0.5 }); // Set initial position to center
  let sceneHandler = null;
  const margin = 20;

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const rect = canvas.getBoundingClientRect();
    const width = canvas.width;
    const height = canvas.height;

    sceneHandler = new SceneHandler(scene);

    const handleMove = (event) => {
      const { clientX, clientY } = event.touches ? event.touches[0] : event;
      const x = (clientX - rect.left) / rect.width; // Normalize x to range [0, 1]
      const y = (clientY - rect.top) / rect.height; // Normalize y to range [0, 1]

      // Clamp the values to the range [0, 1]
      const clampedX = Math.min(1, Math.max(0, x));
      const clampedY = Math.min(1, Math.max(0, y));

      setPosition({ x: clampedX, y: clampedY });
      let selectedMesh = selectedFabricTab;
      if (!selectedFabricTab && scene) {
        let clothMeshGroup = scene.world.getObjectByName("CLOTH_MODEL_GROUP");
        if (clothMeshGroup) {
          clothMeshGroup.children.map((child, index) => {
            if (index === 0) {
              selectedMesh = child.name;
            }
          });
        }
      }
      sceneHandler.handleOffsetChange(clampedX, 1 - clampedY, selectedMesh);
    };

    const handleStart = (event) => {
      handleMove(event);
      window.addEventListener('mousemove', handleMove);
      window.addEventListener('touchmove', handleMove);
    };

    const handleEnd = () => {
      window.removeEventListener('mousemove', handleMove);
      window.removeEventListener('touchmove', handleMove);
      setPosition({ x: 0.5, y: 0.5 }); // Reset to center
    };

    canvas.addEventListener('mousedown', handleStart);
    canvas.addEventListener('touchstart', handleStart);
    window.addEventListener('mouseup', handleEnd);
    window.addEventListener('touchend', handleEnd);

    return () => {
      canvas.removeEventListener('mousedown', handleStart);
      canvas.removeEventListener('touchstart', handleStart);
      window.removeEventListener('mouseup', handleEnd);
      window.removeEventListener('touchend', handleEnd);
      window.removeEventListener('mousemove', handleMove);
      window.removeEventListener('touchmove', handleMove);
    };
  }, [selectedFabricTab]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const width = canvas.width;
    const height = canvas.height;
    ctx.clearRect(0, 0, width, height);
    ctx.beginPath();
    ctx.arc(width * position.x, height * position.y, 20, 0, 2 * Math.PI);
    ctx.fillStyle = 'lightblue';
    ctx.fill();
    ctx.stroke();
  }, [position]);

  return <canvas ref={canvasRef} width={100} height={100} style={{ border: '1px solid blue' }} />;
};

export default Joystick;
