import * as React from 'react';
import Popover from '@mui/material/Popover';

export const PopOverComponent = ({ children, heading, link }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <div className='flex flex-col items-start'>
      <div onClick={handleClick} className='flex items-center  justify-center gap-4'>
        <div className='border border-[#C5D9F4] rounded-[10px] w-[74px] h-[74px] flex items-center justify-center'>
          <img src={link} />
        </div>
        <h4 className='lg:text-3xl'>{heading}</h4>
      </div>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <div className='p-4 flex flex-col lg:min-w-[297px]'>
          {children}
        </div>
        {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
      </Popover>
    </div>
  )
}
