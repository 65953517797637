import { Link } from "react-router-dom"

export const Footer = () => {
  return (
    <div className="
      bg-[#363636]
      space-y-4
      py-8
      ">
      {/* TODO: logo */}
      <div className="flex items-center justify-center py-6">

        <Link to={"/"}>
          <img
            style={{
              height: "55px",
              width: "139px"
            }}
            src='./Logo.png' />
        </Link>
      </div>


      {/* TODO: Paragraph */}
      <div>
        <p className="text-white lg:w-[607px] text-center m-auto">Louoj Bespoke Software offers Bespoke Clothing Business with great interactive design
          , technological support,
          collaborating features for user-friendly experience. Copyright All Rights Reserved.</p>
      </div>


      {/* TODO: grid container  */}
      <div className="grid lg:grid-cols-5 px-4 lg:px-20 py-4 justify-between gap-10">

        <div className="space-y-4">
          <h4 className="font-bold text-white">Address 1</h4>
          <p className="text-white text-sm">20 Cecil Street, #05-03 Plus, Singapore-049705</p>
        </div>

        <div className="space-y-4">
          <h4 className="font-bold text-white">Address 2</h4>
          <p className="
            text-white
            text-sm
            ">Plot# 787/1353/3761, Lane No-01, Sampur, Bhubaneswar, India</p>
        </div>

        <div className="space-y-4">
          <h4 className="font-bold text-white">Call us:</h4>
          <p className="
            text-white
            text-sm
            ">
            +91 76839 95124
          </p>
        </div>

        <div className="space-y-4">
          <h4 className="font-bold text-white">Mail at :</h4>
          <p className="
            text-white
            text-sm
            ">
            contact@louoj.com
          </p>

          <p className="
            text-white
            text-sm
            ">
            louojtech@gmail.com
          </p>

          <p className="
            text-white
            text-sm
            ">
            louojtech@gmail.com
          </p>
        </div>

        <div className="space-y-4">
          <h4 className="font-bold text-white">Get in touch:</h4>
          <p className="
            text-[#0072B5]
            text-sm
            ">
            www.louoj.com
          </p>

          <p className="
            text-[#0072B5]
            text-sm
            ">
            Terms Of Use
          </p>
        </div>
      </div>
      <div className="border border-[#363636] h-2 w-[80%] m-auto flex items-center justify-center"></div>
      <div className="">
        <p className="text-center text-white text-sm">Copyright By LOUOJ 2024</p>
      </div>
    </div>
  )

} 
