// export const catalogue = {
//   templates: {
//     // "all": [
//     //     {
//     //         sku: {
//     //             imgURL: "",
//     //             modelURL: "",
//     //             metadata: {}
//     //         }
//     //     },
//     //     {
//     //         sku: {
//     //             imgURL: "",
//     //             modelURL: "",
//     //             metadata: {}
//     //         }
//     //     }
//     // ],
//     shirt: [
//       {
//         name: "Shirt",
//         imgURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/Thumbnails/Male+shirt.png",
//         modelURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/shirt/glb/shirt+model.glb",
//         shadowGLBURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/shirt/glb/shadow.glb",
//         faceGLBURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/shirt/glb/face+and+hand.glb",
//         metadata: {},
//       },
//       {
//         name: "Shirt-2",
//         imgURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/Thumbnails/Male+shirt.png",
//         modelURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/shirt2/shirt+model.glb",
//         shadowGLBURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/shirt2/shirt+shadow.glb",
//         faceGLBURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/shirt2/face+and+hand.glb",
//         metadata: {},
//       },
//     ],
//
//     suit: [
//       {
//         name: "Suit",
//         imgURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/Thumbnails/male+suit.jpg",
//         modelURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/suit/suit+model.glb",
//         shadowGLBURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/suit/suit+shadow.glb",
//         faceGLBURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/suit/white+plane+BG.glb",
//         metadata: {},
//       },
//     ],
//
//     kurti: [
//       {
//         name: "Kurti",
//         imgURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/Thumbnails/Kurti+.png",
//         modelURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/kurti/Kurti+model.glb",
//         shadowGLBURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/kurti/Kurti+shadow.glb",
//         faceGLBURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/kurti/Kurti+body+and+hands.glb",
//         metadata: {},
//       },
//     ],
//     "female shirt": [
//       {
//         name: "Female Shirt",
//         imgURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/Thumbnails/Female+shirt.jpg",
//         modelURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/w_shirt/Shirt+base+color.glb",
//         shadowGLBURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/w_shirt/Shirt+shadow.glb",
//         faceGLBURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/w_shirt/White+plane+BG.glb",
//         metadata: {},
//       },
//       {
//         name: "Female Shirt",
//         imgURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/Thumbnails/Female+shirt.jpg",
//         modelURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/w_shirt/Shirt+base+color.glb",
//         shadowGLBURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/w_shirt/Shirt+shadow.glb",
//         faceGLBURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/w_shirt/White+plane+BG.glb",
//         metadata: {},
//       },
//     ],
//
//     trouser: [
//       {
//         name: "Trouser",
//         imgURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/Thumbnails/Trousers.jpg",
//         modelURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/Trouser/Trousers.glb",
//         shadowGLBURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/Trouser/Trouser+shadow.glb",
//         faceGLBURL: "",
//         metadata: {},
//       },
//     ],
//     "t-shirt": [
//       {
//         name: "T-shirt",
//         imgURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/Thumbnails/t+shirt.png",
//         modelURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/t-shirt/t+shirt+model.glb",
//         shadowGLBURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/t-shirt/t+shirt+shadow.glb",
//         faceGLBURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/t-shirt/white+plane+BG.glb",
//
//         metadata: {},
//       },
//     ],
//     "anarkali": [
//       {
//         name: "Anarkali Dress",
//         imgURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/Thumbnails/Kurti+front.png",
//         modelURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/anarkali/Anarkali+dress.glb",
//         //shadowGLBURL: "https://s3-smartco.s3.amazonaws.com/louoj3d/anarkali/t+shirt+shadow.glb",
//         faceGLBURL:
//           "https://s3-smartco.s3.amazonaws.com/louoj3d/anarkali/Anarkali+dress+face.glb",
//
//         metadata: {},
//       },
//     ],
//
//     // "mask": [
//     //     {
//     //         sku: {
//     //             imgURL: "",
//     //             modelURL: "../../public/models/shirt.glb",
//     //             shadowGLBURL: "",
//     //             faceGLBURL: "",
//     //             metadata: {}
//     //         }
//     //     },
//     //     {
//     //         sku: {
//     //             imgURL: "",
//     //             modelURL: "../../public/models/shirt.glb",
//     //             shadowGLBURL: "",
//     //             faceGLBURL: "",
//     //             metadata: {}
//     //         }
//     //     }
//     // ],
//     // "suit": [
//     //     {
//     //         sku: {
//     //             imgURL: "",
//     //             modelURL: "../../public/models/shirt.glb",
//     //             shadowGLBURL: "",
//     //             faceGLBURL: "",
//     //             metadata: {}
//     //         }
//     //     },
//     //     {
//     //         sku: {
//     //             imgURL: "",
//     //             modelURL: "../../public/models/shirt.glb",
//     //             shadowGLBURL: "",
//     //             faceGLBURL: "",
//     //             metadata: {}
//     //         }
//     //     }
//     // ],
//
//     // "nehru jacket": [
//     //     {
//     //         sku: {
//     //             imgURL: "",
//     //             modelURL: "../../public/models/shirt.glb",
//     //             shadowGLBURL: "",
//     //             faceGLBURL: "",
//     //             metadata: {}
//     //         }
//     //     },
//     //     {
//     //         sku: {
//     //             imgURL: "",
//     //             modelURL: "../../public/models/shirt.glb",
//     //             shadowGLBURL: "",
//     //             faceGLBURL: "",
//     //             metadata: {}
//     //         }
//     //     }
//     // ],
//     // "kids": [
//     //     {
//     //         sku: {
//     //             imgURL: "",
//     //             modelURL: "../../public/models/shirt.glb",
//     //             shadowGLBURL: "",
//     //             faceGLBURL: "",
//     //             metadata: {}
//     //         }
//     //     },
//     //     {
//     //         sku: {
//     //             imgURL: "",
//     //             modelURL: "../../public/models/shirt.glb",
//     //             shadowGLBURL: "",
//     //             faceGLBURL: "",
//     //             metadata: {}
//     //         }
//     //     }
//     // ],
//     // "dress": [
//     //     {
//     //         sku: {
//     //             imgURL: "",
//     //             modelURL: "../../public/models/shirt.glb",
//     //             shadowGLBURL: "",
//     //             faceGLBURL: "",
//     //             metadata: {}
//     //         }
//     //     },
//     //     {
//     //         sku: {
//     //             imgURL: "",
//     //             modelURL: "../../public/models/shirt.glb",
//     //             shadowGLBURL: "",
//     //             faceGLBURL: "",
//     //             metadata: {}
//     //         }
//     //     }
//     // ]
//   },
//   fabrics: [
//     {
//       name: "Fabric1",
//       fabricimgURL:
//         "https://d2wtrdhpq3drc9.cloudfront.net/louoj3d/fabrics/print+1.jpg",
//     },
//     {
//       name: "Fabric2",
//       fabricimgURL:
//         "https://d2wtrdhpq3drc9.cloudfront.net/louoj3d/fabrics/fabric+2.jpg",
//     },
//     {
//       name: "Fabric3",
//       fabricimgURL:
//         "https://d2wtrdhpq3drc9.cloudfront.net/louoj3d/fabrics/fabric+3.jpg",
//     },
//     {
//       name: "Fabric4",
//       fabricimgURL:
//         "https://d2wtrdhpq3drc9.cloudfront.net/louoj3d/fabrics/fabric+4.jpg",
//     },
//     {
//       name: "Fabric5",
//       fabricimgURL:
//         "https://d2wtrdhpq3drc9.cloudfront.net/louoj3d/fabrics/fabric+5.jpg",
//     },
//     {
//       name: "Fabric6",
//       fabricimgURL:
//         "https://d2wtrdhpq3drc9.cloudfront.net/louoj3d/fabrics/print+1.jpg",
//     },
//   ],
// };


const baseURL = "https://d12hs8wunnl6k1.cloudfront.net/louoj3d";

export const catalogue = {
  templates: {
    // "all": [
    //     {
    //         sku: {
    //             imgURL: "",
    //             modelURL: "",
    //             metadata: {}
    //         }
    //     },
    //     {
    //         sku: {
    //             imgURL: "",
    //             modelURL: "",
    //             metadata: {}
    //         }
    //     }
    // ],
    shirt: [
      {
        name: "Shirt",
        imgURL: `${baseURL}/Thumbnails/Male+shirt.png`,
        modelURL: `${baseURL}/shirt/glb/shirt+model.glb`,
        shadowGLBURL: `${baseURL}/shirt/glb/shadow.glb`,
        faceGLBURL: `${baseURL}/shirt/glb/face+and+hand.glb`,
        metadata: {},
      },
      {
        name: "Shirt-2",
        imgURL: `${baseURL}/Thumbnails/Male+shirt.png`,
        modelURL: `${baseURL}/shirt2/shirt+model.glb`,
        shadowGLBURL: `${baseURL}/shirt2/shirt+shadow.glb`,
        faceGLBURL: `${baseURL}/shirt2/face+and+hand.glb`,
        metadata: {},
      },
    ],

    suit: [
      {
        name: "Suit",
        imgURL: `${baseURL}/Thumbnails/male+suit.jpg`,
        modelURL: `${baseURL}/suit/suit+model.glb`,
        shadowGLBURL: `${baseURL}/suit/suit+shadow.glb`,
        faceGLBURL: `${baseURL}/suit/white+plane+BG.glb`,
        metadata: {},
      },
    ],

    kurti: [
      {
        name: "Kurti",
        imgURL: `${baseURL}/Thumbnails/Kurti+.png`,
        modelURL: `${baseURL}/kurti/Kurti+model.glb`,
        shadowGLBURL: `${baseURL}/kurti/Kurti+shadow.glb`,
        faceGLBURL: `${baseURL}/kurti/Kurti+body+and+hands.glb`,
        metadata: {},
      },
    ],
    "female shirt": [
      {
        name: "Female Shirt",
        imgURL: `${baseURL}/Thumbnails/Female+shirt.jpg`,
        modelURL: `${baseURL}/w_shirt/Shirt+base+color.glb`,
        shadowGLBURL: `${baseURL}/w_shirt/Shirt+shadow.glb`,
        faceGLBURL: `${baseURL}/w_shirt/White+plane+BG.glb`,
        metadata: {},
      },
      {
        name: "Female Shirt",
        imgURL: `${baseURL}/Thumbnails/Female+shirt.jpg`,
        modelURL: `${baseURL}/w_shirt/Shirt+base+color.glb`,
        shadowGLBURL: `${baseURL}/w_shirt/Shirt+shadow.glb`,
        faceGLBURL: `${baseURL}/w_shirt/White+plane+BG.glb`,
        metadata: {},
      },
    ],

    trouser: [
      {
        name: "Trouser",
        imgURL: `${baseURL}/Thumbnails/Trousers.jpg`,
        modelURL: `${baseURL}/Trouser/Trousers.glb`,
        shadowGLBURL: `${baseURL}/Trouser/Trouser+shadow.glb`,
        faceGLBURL: "",
        metadata: {},
      },
    ],
    "t-shirt": [
      {
        name: "T-shirt",
        imgURL: `${baseURL}/Thumbnails/t+shirt.png`,
        modelURL: `${baseURL}/t-shirt/t+shirt+model.glb`,
        shadowGLBURL: `${baseURL}/t-shirt/t+shirt+shadow.glb`,
        faceGLBURL: `${baseURL}/t-shirt/white+plane+BG.glb`,
        metadata: {},
      },
    ],
    anarkali: [
      {
        name: "Anarkali Dress",
        imgURL: `${baseURL}/Thumbnails/Kurti+front.png`,
        modelURL: `${baseURL}/anarkali/Anarkali+dress.glb`,
        //shadowGLBURL: `${baseURL}/anarkali/t+shirt+shadow.glb`,
        faceGLBURL: `${baseURL}/anarkali/Anarkali+dress+face.glb`,
        metadata: {},
      },
    ],

    // "mask": [
    //     {
    //         sku: {
    //             imgURL: "",
    //             modelURL: "../../public/models/shirt.glb",
    //             shadowGLBURL: "",
    //             faceGLBURL: "",
    //             metadata: {}
    //         }
    //     },
    //     {
    //         sku: {
    //             imgURL: "",
    //             modelURL: "../../public/models/shirt.glb",
    //             shadowGLBURL: "",
    //             faceGLBURL: "",
    //             metadata: {}
    //         }
    //     }
    // ],
    // "suit": [
    //     {
    //         sku: {
    //             imgURL: "",
    //             modelURL: "../../public/models/shirt.glb",
    //             shadowGLBURL: "",
    //             faceGLBURL: "",
    //             metadata: {}
    //         }
    //     },
    //     {
    //         sku: {
    //             imgURL: "",
    //             modelURL: "../../public/models/shirt.glb",
    //             shadowGLBURL: "",
    //             faceGLBURL: "",
    //             metadata: {}
    //         }
    //     }
    // ],

    // "nehru jacket": [
    //     {
    //         sku: {
    //             imgURL: "",
    //             modelURL: "../../public/models/shirt.glb",
    //             shadowGLBURL: "",
    //             faceGLBURL: "",
    //             metadata: {}
    //         }
    //     },
    //     {
    //         sku: {
    //             imgURL: "",
    //             modelURL: "../../public/models/shirt.glb",
    //             shadowGLBURL: "",
    //             faceGLBURL: "",
    //             metadata: {}
    //         }
    //     }
    // ],
    // "kids": [
    //     {
    //         sku: {
    //             imgURL: "",
    //             modelURL: "../../public/models/shirt.glb",
    //             shadowGLBURL: "",
    //             faceGLBURL: "",
    //             metadata: {}
    //         }
    //     },
    //     {
    //         sku: {
    //             imgURL: "",
    //             modelURL: "../../public/models/shirt.glb",
    //             shadowGLBURL: "",
    //             faceGLBURL: "",
    //             metadata: {}
    //         }
    //     }
    // ],
    // "dress": [
    //     {
    //         sku: {
    //             imgURL: "",
    //             modelURL: "../../public/models/shirt.glb",
    //             shadowGLBURL: "",
    //             faceGLBURL: "",
    //             metadata: {}
    //         }
    //     },
    //     {
    //         sku: {
    //             imgURL: "",
    //             modelURL: "../../public/models/shirt.glb",
    //             shadowGLBURL: "",
    //             faceGLBURL: "",
    //             metadata: {}
    //         }
    //     }
    // ]
  },
  fabrics: [
    {
      name: "Fabric1",
      fabricimgURL: `${baseURL}/fabrics/print+1.jpg`,
    },
    {
      name: "Fabric2",
      fabricimgURL: `${baseURL}/fabrics/fabric+2.jpg`,
    },
    {
      name: "Fabric3",
      fabricimgURL: `${baseURL}/fabrics/fabric+3.jpg`,
    },
    {
      name: "Fabric4",
      fabricimgURL: `${baseURL}/fabrics/fabric+4.jpg`,
    },
    {
      name: "Fabric5",
      fabricimgURL: `${baseURL}/fabrics/fabric+5.jpg`,
    },
    {
      name: "Fabric6",
      fabricimgURL: `${baseURL}/fabrics/print+1.jpg`,
    },
  ],
};
