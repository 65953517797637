import { Grid } from "@mui/material";
import { motion } from "framer-motion";
import React, { useEffect, useState, useRef } from "react";
import { SceneSetup } from "../utils/SceneSetup";
import Joystick from "./Joystick";
import RepeatSlider from "./RepeatSlider";
import ThreeScene from "./ThreeScene";
import { SimpleBottomNavigation } from './SimpleBottomNavigation';
import Input from '@mui/material/Input';
import { CardMedia, CardContent, Button } from '@mui/material';
import fileUploadImage from '../Assests/images/upload_image.png';
import SceneHandler from "../utils/SceneHandler";
import { ActiveTypeGlobal } from "./PopoverComponentFabrics";
import html2canvas from 'html2canvas';


let sceneHandler = null;

function Visualizer(props) {
  const [scene, setScene] = useState(null);
  const [selectedTemplateTab, setSelectedTemplateTab] = useState(null);
  const [shadowSliderValue, setShadowSliderValue] = React.useState(0.5);
  const [selectedFabricTab, setSelectedFabricTab] = useState(null);
  const [logoPosition, setLogoPosition] = React.useState('Bottom Right');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popoverData, setPopoverData] = React.useState({});
  const fileInputRef = React.createRef();
  const [activeTab, setActiveTab] = React.useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [prevMeshNameList, setPrevMeshNameList] = React.useState([]);


  const captureRef = useRef(null);
  const canvasRef = useRef(null);

  const handleCaptureClick = async () => {
    console.log("working")
    if (captureRef.current) {
      const canvas = await html2canvas(captureRef.current);
      canvasRef.current.innerHTML = '';
      canvasRef.current.appendChild(canvas);
    }
  };



  const handleFileChange = (event) => {
    const userImage = event.target.files[0];
    if (userImage) {
      if (sceneHandler) {
        const meshName = ActiveTypeGlobal;
        const userImageURL = URL.createObjectURL(userImage);
        sceneHandler.fabricChange(userImageURL, meshName);
      }
    }
    setSelectedFile(userImage);
  }



  const handleClick = () => {
    // Trigger the file input click
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  //
  React.useEffect(() => {
    if (scene)
      sceneHandler = new SceneHandler(scene)
  }, [scene])


  const handleTemplateData = (data) => {
    setSelectedTemplateTab(data.selectedTemplateTab);
  }
  const handleFabricData = (data) => {
    setSelectedFabricTab(data.selectedFabricTab);
  }
  // useEffect(() => {
  //   if (sceneDiv !== "")
  //     sceneDiv = document.getElementById('sceneDiv');
  //   if (sceneDiv !== "") {
  //     setScene(new SceneSetup(sceneDiv));
  //     sceneDiv = ""
  //   }
  // }, [sceneDiv]);



  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  let sceneDiv;

  useEffect(() => {
    if (sceneDiv !== "") sceneDiv = document.getElementById("sceneDiv");
    if (sceneDiv !== "") {
      setScene(new SceneSetup(sceneDiv));
      sceneDiv = "";
    }
  }, [sceneDiv]);

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
        }}
        transition={{ duration: 0.5 }}
        className="relative"
      >
        <Grid
          sx={{ height: "100vh" }}
          container
          spacing={0}
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item xs={2} lg={2} container justifyContent="flex-end">
            {scene && (
              <RepeatSlider
                scene={scene}
                selectedFabricTab={selectedFabricTab}
              />
            )}
          </Grid>
          <Grid item xs={10} lg={5} style={{ height: "80vh", width: "50%" }}>
            <ThreeScene ref={captureRef} id="sceneDiv" />
            {/* <button onClick={handleCaptureClick}>Capture</button> */}
            <div ref={canvasRef} style={{ marginTop: '20px' }}></div>
          </Grid>
          <Grid item xs={0} lg={2}>
            {scene && (
              <Joystick scene={scene} selectedFabricTab={selectedFabricTab} />
            )}
          </Grid>
          {/* <Grid item xs={0} className={"space-y-4"}> */}
          {/*   <PopOverComponent heading={"Options"} link={"./option1.png"}> */}
          {/*     <div> */}
          {/*       <div className="space-y-4"> */}
          {/*         <div className="flex items-center gap-4 justify-between"> */}
          {/*           <h2 className="text-2xl">Shadows</h2> */}
          {/*           <Button variant="contained">Reset</Button> */}
          {/*         </div> */}
          {/*         <div className="flex items-center gap-4"> */}
          {/*           <h4>Depth</h4> */}
          {/*           <Slider */}
          {/*             defaultValue={50} */}
          {/*             aria-label="Default" */}
          {/*             valueLabelDisplay="auto" */}
          {/*           /> */}
          {/*         </div> */}
          {/*         <div className="flex items-center gap-4"> */}
          {/*           <h4>Shadows</h4> */}
          {/*           <Slider */}
          {/*             defaultValue={50} */}
          {/*             aria-label="Default" */}
          {/*             valueLabelDisplay="auto" */}
          {/*           /> */}
          {/*         </div> */}
          {/**/}
          {/*         <div className="flex items-center gap-4"> */}
          {/*           <h4>Contact Shadows</h4> */}
          {/*           <Slider */}
          {/*             defaultValue={50} */}
          {/*             aria-label="Default" */}
          {/*             valueLabelDisplay="auto" */}
          {/*           /> */}
          {/*         </div> */}
          {/*         <h2 className="text-2xl">Logo Settings</h2> */}
          {/**/}
          {/*         <div className="flex items-center gap-4"> */}
          {/*           <h4>Logo Scale</h4> */}
          {/*           <Slider */}
          {/*             defaultValue={50} */}
          {/*             aria-label="Default" */}
          {/*             valueLabelDisplay="auto" */}
          {/*           /> */}
          {/*         </div> */}
          {/*         <div className="flex items-center"> */}
          {/*           <h4>Logo Position</h4> */}
          {/*           <FormControl fullWidth> */}
          {/*             <InputLabel id="demo-simple-select-label"> */}
          {/*               Position */}
          {/*             </InputLabel> */}
          {/*             <Select */}
          {/*               labelId="demo-simple-select-label" */}
          {/*               id="demo-simple-select" */}
          {/*               label="Age" */}
          {/*             > */}
          {/*               <MenuItem value={10}>Bottom Right</MenuItem> */}
          {/*               <MenuItem value={20}>Bottom Left</MenuItem> */}
          {/*               <MenuItem value={30}>Top Right</MenuItem> */}
          {/*               <MenuItem value={30}>Top Left</MenuItem> */}
          {/*             </Select> */}
          {/*           </FormControl> */}
          {/*         </div> */}
          {/*       </div> */}
          {/*     </div> */}
          {/*   </PopOverComponent> */}
          {/*   <PopOverComponent heading={"Templates"} link={"./option2.png"}> */}
          {/*     <div className="space-y-4"> */}
          {/*       <div className="flex items-center justify-center border p-2 rounded-[10px] gap-2 border-[#A6A6A6]"> */}
          {/*         <input className="w-full outline-none" placeholder="Search" /> */}
          {/*         <SearchRounded /> */}
          {/*       </div> */}
          {/*       <div className="flex items-center justify-between"> */}
          {/*         <h3 */}
          {/*           className=" */}
          {/*           text-[16px] */}
          {/*           font-[500] */}
          {/*           " */}
          {/*         > */}
          {/*           Shirt */}
          {/*         </h3> */}
          {/*         <h3 */}
          {/*           className=" */}
          {/*           text-[16px] */}
          {/*           font-[500] */}
          {/*           " */}
          {/*         > */}
          {/*           Kurti */}
          {/*         </h3> */}
          {/*         <h3 */}
          {/*           className=" */}
          {/*           text-[16px] */}
          {/*           font-[500] */}
          {/*           " */}
          {/*         > */}
          {/*           Female Shirt */}
          {/*         </h3> */}
          {/*         <h3 */}
          {/*           className=" */}
          {/*           text-[16px] */}
          {/*           font-[500] */}
          {/*           " */}
          {/*         > */}
          {/*           Trouser */}
          {/*         </h3> */}
          {/*       </div> */}
          {/*       <div className="grid grid-cols-2"> */}
          {/*         {catalogue.templates.shirt.map((_i, key) => ( */}
          {/*           <div key={key} className="cursor-pointer "> */}
          {/*             <img className="w-32 h-40" src={_i.imgURL} /> */}
          {/*             <h4>{_i.name}</h4> */}
          {/*           </div> */}
          {/*         ))} */}
          {/*       </div> */}
          {/*     </div> */}
          {/*   </PopOverComponent> */}
          {/*   <PopOverComponent heading={"Fabric"} link={"./option3.png"}> */}
          {/*     <div className="space-y-4"> */}
          {/*       <div className="flex items-center justify-center border p-2 rounded-[10px] gap-2 border-[#A6A6A6]"> */}
          {/*         <input className="w-full outline-none" placeholder="Search" /> */}
          {/*         <SearchRounded /> */}
          {/*       </div> */}
          {/*       <div className="grid grid-cols-3 gap-2"> */}
          {/*         {catalogue.fabrics.map((_i, key) => ( */}
          {/*           <div key={key} className="cursor-pointer space-y-1"> */}
          {/*             <img className="w-24 h-24" src={_i.fabricimgURL} /> */}
          {/*             <h4 className="text-center font-bold">{_i.name}</h4> */}
          {/*           </div> */}
          {/*         ))} */}
          {/*       </div> */}
          {/*     </div> */}
          {/*   </PopOverComponent> */}
          {/*   <PopOverComponent heading={"Download"} link={"./option4.png"}> */}
          {/*     <div className="space-y-4"> */}
          {/*       <p className="text-xl">Download Setting</p> */}
          {/*       <div className="flex items-center gap-4"> */}
          {/*         <input type="checkbox" /> */}
          {/*         <p className="text-[#787878]">Sharpen Image</p> */}
          {/*       </div> */}
          {/*       <div className="flex items-center gap-4"> */}
          {/*         <input type="checkbox" /> */}
          {/*         <p className="text-[#787878]"> */}
          {/*           Download with Template Resolution */}
          {/*         </p> */}
          {/*       </div> */}
          {/*       <div className="space-y-1"> */}
          {/*         <p className="text-[#787878] text-sm">Width</p> */}
          {/*         <input className="border border-[#D9D9D9] px-[15px] py-1 rounded-[5px] w-full outline-none" /> */}
          {/*       </div> */}
          {/*       <div className="space-y-1"> */}
          {/*         <p className="text-[#787878] text-sm">Height</p> */}
          {/*         <input className="border border-[#D9D9D9] px-[15px] py-1 w-full rounded-[5px] outline-none" /> */}
          {/*       </div> */}
          {/**/}
          {/*       <div className="flex items-center "> */}
          {/*         <Switch */}
          {/*           checked={checked} */}
          {/*           onChange={handleChange} */}
          {/*           inputProps={{ "aria-label": "controlled" }} */}
          {/*         /> */}
          {/*         <p className="text-[#787878] text-sm">Bulk Download</p> */}
          {/*       </div> */}
          {/**/}
          {/*       {checked ? ( */}
          {/*         <div> */}
          {/*           <div className="flex items-center "> */}
          {/*             <Switch inputProps={{ "aria-label": "controlled" }} /> */}
          {/*             <p className="text-[#787878] text-sm">Outfits Download</p> */}
          {/*           </div> */}
          {/*           <div> */}
          {/*             <p className="text-sm text-[#787878]"> */}
          {/*               Bulk download will not work on Safari browsers and iOS */}
          {/*               devices. */}
          {/*             </p> */}
          {/*           </div> */}
          {/*           <div className="flex items-center justify-between my-4 first:bg-[#0072B5] first:text-white"> */}
          {/*             <button className="hover:bg-[#0072B5] text-[#787878] bg-[#F8F8F8] hover:text-white py-2 px-4 rounded-full"> */}
          {/*               Default */}
          {/*             </button> */}
          {/*             <button className="hover:bg-[#0072B5] text-[#787878] bg-[#F8F8F8] hover:text-white py-2 px-4 rounded-full"> */}
          {/*               All Slotes */}
          {/*             </button> */}
          {/*             <button className="hover:bg-[#0072B5] text-[#787878] bg-[#F8F8F8] hover:text-white py-2 px-4 rounded-full"> */}
          {/*               Custom Slotes */}
          {/*             </button> */}
          {/*           </div> */}
          {/*           <div className="space-y-4"> */}
          {/*             <div className="flex items-center"> */}
          {/*               <Switch inputProps={{ "aria-label": "controlled" }} /> */}
          {/*               <div className="flex items-center gap-2 "> */}
          {/*                 <div className="relative"> */}
          {/*                   <h3 className="text-xl text-[#787878] mt-2 mr-4"> */}
          {/*                     Template Tag */}
          {/*                   </h3> */}
          {/*                   <h4 className="absolute text-red-800 right-0 top-0 text-xs"> */}
          {/*                     New */}
          {/*                   </h4> */}
          {/*                 </div> */}
          {/*                 <p className="text-xs text-[#787878]"> */}
          {/*                   (templates in the tag will only be considered for */}
          {/*                   bulk) */}
          {/*                 </p> */}
          {/*               </div> */}
          {/*             </div> */}
          {/**/}
          {/*             <div className="flex items-center"> */}
          {/*               <Switch inputProps={{ "aria-label": "controlled" }} /> */}
          {/*               <div className="flex items-center gap-2 "> */}
          {/*                 <div className="relative"> */}
          {/*                   <h3 className="text-xl text-[#787878] mt-2 mr-4"> */}
          {/*                     Garment Tag */}
          {/*                   </h3> */}
          {/*                   <h4 className="absolute text-red-800 right-0 top-0 text-xs"> */}
          {/*                     New */}
          {/*                   </h4> */}
          {/*                 </div> */}
          {/*                 <p className="text-xs text-[#787878]"> */}
          {/*                   (Garment in the tag will only be considered for */}
          {/*                   bulk) */}
          {/*                 </p> */}
          {/*               </div> */}
          {/*             </div> */}
          {/*           </div> */}
          {/*           <div className="flex items-center justify-center my-4"> */}
          {/*             <p className="text-xs text-[#787878] flex-1"> */}
          {/*               No garments in bulk. Please go to <br /> the Gallery */}
          {/*               page to add to the bulk. */}
          {/*             </p> */}
          {/*             <p className="text-xs text-[#787878] flex-1"> */}
          {/*               No garments in bulk. Please go to <br /> the Gallery */}
          {/*               page to add to the bulk. */}
          {/*             </p> */}
          {/*           </div> */}
          {/*           <div className="flex items-center gap-2"> */}
          {/*             <input type="checkbox" /> */}
          {/*             <p className="text-xs"> */}
          {/*               Use fabric's default magnification */}
          {/*             </p> */}
          {/*           </div> */}
          {/*           <div className="my-4 space-y-2"> */}
          {/*             <h3 className="font-bold text-xs">0 magnifications</h3> */}
          {/*             <input className="border border-[#D9D9D9] p-1" /> */}
          {/*           </div> */}
          {/*         </div> */}
          {/*       ) : null} */}
          {/*     </div> */}
          {/*   </PopOverComponent> */}
          {/**/}
          {/*   <PopOverComponent heading={"Share"} link={"./option5.png"}> */}
          {/*     <div className="space-y-4"> */}
          {/*       <div className="flex items-center justify-start gap-4 "> */}
          {/*         <input type="checkbox" /> */}
          {/*         <p>Share only Image</p> */}
          {/*       </div> */}
          {/*       <div className="flex items-center justify-center gap-4 border border-black py-2 rounded-[5px]"> */}
          {/*         <img src="./facebook.png" className="w-[30px] h-[30px]" /> */}
          {/*         <p className="font-[16px]">Facebook</p> */}
          {/*       </div> */}
          {/*       <div className="flex items-center justify-center gap-4 border border-black py-2 rounded-[5px]"> */}
          {/*         <img src="./whatsapp.png" className="w-[30px] h-[30px]" /> */}
          {/*         <p className="font-[16px]">WhatsApp</p> */}
          {/*       </div> */}
          {/*       <div className="flex items-center justify-center gap-4 border border-black py-2 rounded-[5px]"> */}
          {/*         <img src="./linkdin.png" className="w-[30px] h-[30px]" /> */}
          {/*         <p className="font-[16px]">Linkedln</p> */}
          {/*       </div> */}
          {/**/}
          {/*       <div> */}
          {/*         <div className="flex items-center border border-black rounded-[8px]"> */}
          {/*           <input */}
          {/*             value={ */}
          {/*               "https://fabric.tri3d.in/?pose_name=a1_all_set198_shirt" */}
          {/*             } */}
          {/*             placeholder="Enter url" */}
          {/*             className="py-2 pl-2 outline-none w-[80%] text-sm" */}
          {/*           /> */}
          {/*           <button className="bg-[#ECECEC] text-black w-[20%] px-3 py-2"> */}
          {/*             Copy Link */}
          {/*           </button> */}
          {/*         </div> */}
          {/*       </div> */}
          {/*     </div> */}
          {/*   </PopOverComponent> */}
          {/* </Grid> */}

          <div className="hidden lg:block">
            <Grid item xs={12} lg={3}> {scene && <SimpleBottomNavigation
              scene={scene}
              sendTemplateDataToParent={handleTemplateData}
              sendFabricsDataToParent={handleFabricData} />} </Grid>
          </div>
        </Grid>

        <div className="lg:hidden">
          <Grid item xs={12} lg={3}>
            {scene && <SimpleBottomNavigation
              scene={scene}
              sendTemplateDataToParent={handleTemplateData}
              sendFabricsDataToParent={handleFabricData} />}
          </Grid>
        </div>

        {/* <div className="absolute top-4 left-4 w-[100px]"> */}
        {/*   <CardMedia */}
        {/*     component="img" */}
        {/*     height="140" */}
        {/*     image={fileUploadImage} */}
        {/*     alt={"Upload Fabric"} */}
        {/*     onClick={handleClick} */}
        {/*     style={{ cursor: 'pointer' }} */}
        {/*   /> */}
        {/*   <CardContent> */}
        {/*     <Button onClick={handleClick} variant="contained" color="primary"> */}
        {/*       Upload Fabric */}
        {/*     </Button> */}
        {/*     <Input */}
        {/*       type="file" */}
        {/*       accept="image/*" */}
        {/*       style={{ display: 'none' }} */}
        {/*       onChange={handleFileChange} */}
        {/*       onClick={(e) => (e.target.value = null)} // Reset input value to allow selecting the same file */}
        {/*       inputRef={fileInputRef} // Use inputRef instead of ref */}
        {/*     /> */}
        {/*     {selectedFile && ( */}
        {/*       <div> */}
        {/*         <p>Selected File: {selectedFile.name}</p> */}
        {/*       </div> */}
        {/*     )} */}
        {/*   </CardContent> */}
        {/* </div> */}
        <div>
          <button onClick={handleClick} className="absolute top-4 left-4 lg:top-40 lg:left-40 bg-[#0072B5] px-4 py-2 text-white rounded-md">
            Upload Fabric
          </button>

          <Input
            type="file"
            accept="image/png ,image/jpeg"
            style={{ display: 'none' }}
            onChange={handleFileChange}
            onClick={(e) => (e.target.value = null)} // Reset input value to allow selecting the same file
            inputRef={fileInputRef} // Use inputRef instead of ref
          />

          {selectedFile && (
            <div>
              <p>Selected File: {selectedFile.name}</p>
            </div>
          )}
        </div>
      </motion.div >
    </>
  );
}

export default Visualizer;
