import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import texturePath from "../Assests/fabric+5.jpg"

export default class SceneController {

  updateFabric(fabricURL, scene,meshName) {
    const texture = new THREE.TextureLoader();

    texture.load(
      fabricURL,
      function (newTexture) {
        const shirtMesh = scene.getObjectByName(meshName);
        if (shirtMesh) {
          newTexture.wrapS = THREE.RepeatWrapping;
          newTexture.wrapT = THREE.RepeatWrapping;
          shirtMesh.material.map = newTexture;
          shirtMesh.material.needsUpdate = true;
        }
      },
      function (xhr) {
        console.log((xhr.loaded / xhr.total * 100) + '% loaded');
      },
      function (error) {
        console.error('Error loading new texture:', error);
      }
    );
  }

  disposeScene(scene) {
    scene.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        if (child.geometry) {
          child.geometry.dispose();
        }
        if (child.material) {
          if (Array.isArray(child.material)) {
            child.material.forEach(material => {
              if (material.map) {
                material.map.dispose();
              }
              material.dispose();
            });
          } else {
            if (child.material.map) {
              child.material.map.dispose();
            }
            child.material.dispose();
          }
        }
      }
    })

    let modelsToBeRemoved = ["CLOTH_MODEL_GROUP", "SHADOW_MAP_MODEL_GROUP", "FACE_MODEL_GROUP"];

    modelsToBeRemoved.forEach(element => {
      let obj = scene.getObjectByName(element);
      if (obj) {
        obj.removeFromParent();
      }
    });
  }
//Rushi
  loadGLTF(paths, scene) {
    // const promise = new Promise((resolve, reject)=>{
      const texture = new THREE.TextureLoader().load(texturePath);
      const loader = new GLTFLoader();
      let modelPaths = [paths.modelURL, paths.faceGLBURL, paths.shadowGLBURL];
      // const modelMeshNameList = [];
      modelPaths.forEach((path, index) => {
        loader.load(
          path,
          function (gltf) {
            scene.add(gltf.scene);
            if (path === paths.modelURL) {
              gltf.scene.children.map((child, index) => {
                if(index === 0){
                  child.userData.active = true;
                }else{
                  child.userData.active = false;
                }
                child.name = child.name.toUpperCase();
                // modelMeshNameList.push(child.name);
                child.material.color = new THREE.Color(1,1,1);
              });
              gltf.scene.name = "CLOTH_MODEL_GROUP"; 
              
              const camera = scene.getObjectByName("camera");
              if (camera) {
                // console.log(camera.position)
                const bbox = new THREE.Box3().setFromObject(gltf.scene);
                let center = new THREE.Vector3();
                bbox.getCenter(center);
                const bboxSize = new THREE.Vector3();
                bbox.getSize(bboxSize);
                const maxBoundingBoxSize = Math.max(bboxSize.x, bboxSize.y);
  
                const cameraDistance = maxBoundingBoxSize / Math.tan(THREE.MathUtils.degToRad(camera.fov / 2))
  
                camera.position.set(center.x,center.y,cameraDistance);
              }
              // gltf.scene.children[0].name = "shirtModel";
              texture.wrapS = THREE.RepeatWrapping;
              texture.wrapT = THREE.RepeatWrapping;
              texture.offset.x = 0.5;
              texture.offset.y = 0.5;
              texture.repeat = new THREE.Vector2(0.5,0.5);
              texture.matrixAutoUpdate = true;
              gltf.scene.children[0].material.map = texture
              // resolve(modelMeshNameList);
            }
            if (path === paths.faceGLBURL || path === paths.shadowGLBURL) {
              gltf.scene.children[0].material.transparent = true;
              if (path === paths.shadowGLBURL) {
                gltf.scene.name = "SHADOW_MAP_MODEL_GROUP";
                gltf.scene.children[0].material.depthTest = false;
                gltf.scene.children[0].material.depthWrite = false;
                gltf.scene.children[0].name = "shadowModel";
                gltf.scene.children[0].material.opacity=0;
              } else {
                gltf.scene.name = "FACE_MODEL_GROUP";
                gltf.scene.children[0].name = "faceModel";
                gltf.scene.children[0].material.depthTest = false;
                gltf.scene.children[0].material.depthWrite = false;
              }
            }
            console.log(`Model ${index + 1} loaded successfully`);
          },
          function (xhr) {
            console.log(`Model ${index + 1}: ${(xhr.loaded / xhr.total) * 100}% loaded`);
          },
          function (error) {
            console.error(`An error occurred while loading Model ${index + 1}`, error);
            // reject();
          }
        );
      });
      
    // });
    // return promise;

    // modelPaths.forEach(async (path, index) => {
    //   const decyptedData = await this.decrypt3DModel(path);
    //   loader.parse(decyptedData, null,
    //     function (gltf) {
    //       scene.add(gltf.scene);
    //       if (path === paths.modelURL) {
    //         gltf.scene.children[0].name = "shirtModel";
    //         gltf.scene.children[0].material.map = texture
    //       }
    //       if (path === paths.faceGLBURL || path === paths.shadowGLBURL) {
    //         gltf.scene.children[0].material.transparent = true;
    //         if (path === paths.shadowGLBURL) {
    //           gltf.scene.children[0].material.depthTest = false;
    //           gltf.scene.children[0].material.depthWrite = false;
    //           gltf.scene.children[0].name = "shadowModel";
    //         } else {
    //           gltf.scene.children[0].name = "faceModel";
    //         }
    //       }
    //       console.log(`Model ${index + 1} loaded successfully`);
    //     }, null)
    // });

  }

  updateRepeatValues(repeatValue, scene,meshName) {
    const shirtMesh = scene.getObjectByName(meshName);
    if (shirtMesh && shirtMesh.material.map) {
      shirtMesh.material.map.repeat.x = repeatValue;
      shirtMesh.material.map.repeat.y = repeatValue;
      // this.updateUVs(shirtMesh.material.map, repeatValue, repeatValue, shirtMesh.material.map.offset.x, shirtMesh.material.map.offset.y);
    }
  }

  updateShadows(opacity, scene) {
    const shadowModel = scene.getObjectByName("shadowModel");
    if (shadowModel) {
      shadowModel.material.opacity = opacity;
    }
  }

  updateOffsetValues(x, y, scene,meshName) {
    const shirtMesh = scene.getObjectByName(meshName);
    // console.log("x: ", x);
    // console.log("y: ", y);
    if (shirtMesh && shirtMesh.material.map) {
      shirtMesh.material.map.offset.x = x;
      shirtMesh.material.map.offset.y = y;
      // this.updateUVs(shirtMesh.material.map, shirtMesh.material.map.repeat.x, shirtMesh.material.map.repeat.y, x, y);
    }
  }

  updateUVs(texture, repeatX, repeatY, offsetX, offsetY) {
    texture.matrix
      .identity()
      .translate(0, 0)
      .rotate(0)					// I don't understand how rotation can preceed scale, but it seems to be required...
      .scale(repeatX, repeatY)
      .translate(0, 0)
      .translate(offsetX, offsetY);
  }
  async decrypt3DModel(filePath) {
    try {
      const encryptionKey = process.env.REACT_APP_DECRYPTION_KEY;//'yourEncryptionKey123456789012345678901';
      const iVector = process.env.REACT_APP_IV;
      const initializationVector = new TextEncoder().encode(iVector);
      // Fetch the encrypted GLB file
      const response = await fetch(filePath);
      const encryptedData = await response.arrayBuffer();

      // Ensure the key is the correct length
      const keyBuffer = new Uint8Array(new TextEncoder().encode(encryptionKey)).slice(0, 32);

      // Create a CryptoKey from the key
      const cryptoKey = await crypto.subtle.importKey('raw', keyBuffer, 'AES-CBC', false, ['decrypt']);

      // Decrypt the data
      const decryptedData = await crypto.subtle.decrypt({ name: 'AES-CBC', iv: new Uint8Array(initializationVector) }, cryptoKey, encryptedData);

      return new Uint8Array(decryptedData).buffer;

    } catch (err) {
      console.log(err)
    }
  }

  resetView(scene){
    const camera = scene.getObjectByName("camera");
    if (camera) {
      // console.log(camera.position)
      const bbox = new THREE.Box3().setFromObject(scene);
      let center = new THREE.Vector3();
      bbox.getCenter(center);
      const bboxSize = new THREE.Vector3();
      bbox.getSize(bboxSize);
      const maxBoundingBoxSize = Math.max(bboxSize.x, bboxSize.y);

      const cameraDistance = maxBoundingBoxSize / Math.tan(THREE.MathUtils.degToRad(camera.fov / 2))

      camera.position.set(center.x,center.y,cameraDistance);
    }
  }
}
