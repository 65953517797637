import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import InputSlider from './InputSlider';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import SceneHandler from '../utils/SceneHandler';
import { Slider, SliderMark } from '@mui/material';
let sceneHandler = null;
export default function PopoverComponentOptions({ anchorEl, onClose, data, scene, isOpen }) {

  const open = isOpen;//Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [logoPosition, setLogoPosition] = React.useState('Bottom Right');
  const [shadowSliderValue, setShadowSliderValue] = React.useState(0.0);
  const handleChange = (event) => {
    setLogoPosition(event.target.value);
  };

  React.useEffect(() => {
    sceneHandler = new SceneHandler(scene)
  }, [])

  const shadowChange = (value) => {
    const opacityValue = value;
    setShadowSliderValue(value);
    sceneHandler.shadowChange(opacityValue)
  }

  return (

    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}

    >
      <Box sx={{ width: 300 }} className="p-4">
        <Grid container alignItems="center"
          justify="center" spacing={2}>
          <div className='flex w-full items-center px-4 py-4 justify-between'>
            <h3 className='text-xl'>Shadows</h3>
            <Button variant='contained'>Reset</Button>
          </div>
          <Grid item xs={12} className='flex items-center justify-between gap-4'>
            <p>Depth</p>
            <Slider />
          </Grid>
          <Grid item xs={12}>
            <InputSlider label="Shadows" onSliderChange={shadowChange} defaultValue={shadowSliderValue} min={0.0} max={1} step={0.1}></InputSlider>
          </Grid>

          <Grid item xs={12} className='flex items-center justify-between gap-4'>
            <p>Contact Shadows</p>
            <Slider />
          </Grid>

          <Grid item xs={12}>
            <Typography variant='h5' > Logo Settings</Typography>
          </Grid>

          <Grid item xs={12} className='flex items-center justify-between gap-4'>
            <p>Logo Scale</p>
            <Slider />
          </Grid>
          <Grid item xs={4}>
            <Typography id="input-slider" gutterBottom>
              {"Logo Position"}
            </Typography>
          </Grid>
          <Grid item s={8}>
            <Select
              value={logoPosition}
              onChange={handleChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value={'Top Left'}>Top Left</MenuItem>
              <MenuItem value={'Top Right'}>Top Right</MenuItem>
              <MenuItem value={'Bottom Left'}>Bottom Left</MenuItem>
              <MenuItem value={'Bottom Right'}><em>Bottom Right</em></MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Box>
    </Popover>

  );
}
