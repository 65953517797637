import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import {catalogue} from './Catalogue';
import SceneHandler from "./SceneHandler";
export class SceneSetup {
  constructor(ThreeScene) {
    this.sceneRef = ThreeScene;
    this.world = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(45, ThreeScene.clientWidth / ThreeScene.clientHeight, 0.1, 1000);
    this.camera.name = "camera";
    this.initScene();
  }

  initScene() {
    // Create scene, camera, and renderer
    const renderer = new THREE.WebGLRenderer({antialias:true});
    this.world.background = new THREE.Color(0xffffff);
    renderer.setSize(this.sceneRef.clientWidth, this.sceneRef.clientHeight);

    // Append renderer to the DOM
    this.sceneRef.appendChild(renderer.domElement);


    const controls = new OrbitControls(this.camera, renderer.domElement);
    controls.enableRotate = false;
    controls.enablePan = false;

    // Add ambient light
    const ambientLight = new THREE.AmbientLight(0xffffff, 4); // Color, Intensity
    this.world.add(ambientLight);

    // Position the camera
    this.camera.position.z = 8;
    this.world.add(this.camera);
    // Animation function
    const animate = () => {
      requestAnimationFrame(animate);
      // Render the scene
      renderer.render(this.world, this.camera);
    };
    //load shirt model
    let firstShirtObject = catalogue.templates["shirt"][0];
    let paths = {
      modelURL: firstShirtObject.modelURL,
      shadowGLBURL: firstShirtObject.shadowGLBURL,
      faceGLBURL: firstShirtObject.faceGLBURL
    }
    const sceneHandler = new SceneHandler(this);
    sceneHandler.addglTF(paths);
    // SceneHandler.
    // Start the animation loop
    animate();

    // Handle window resize
    const handleResize = () => {
      const newWidth = this.sceneRef.clientWidth;
      const newHeight = this.sceneRef.clientHeight;

      this.camera.aspect = newWidth / newHeight;
      this.camera.updateProjectionMatrix();

      controls.update()

      renderer.setSize(newWidth, newHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      this.sceneRef.removeChild(renderer.domElement);
    };
  }
}
