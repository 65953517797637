import React, { useState, useEffect } from 'react';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, ButtonBase } from '@mui/material';

export default function ActionAreaCard({ header, imageUrl, handleCardClickHandler }) {
  const [thumbnail, setThumbnail] = useState(null);
  const thumbnailWidth = 300;
  const thumbnailHeight = 300;
  function handleCardClick(event) {
    handleCardClickHandler(event);
  }
  useEffect(() => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.crossOrigin = 'anonymous'; // Handle CORS if required
    img.onload = () => {
      canvas.width = thumbnailWidth;
      canvas.height = thumbnailHeight;
      ctx.drawImage(img, 0, 0, thumbnailWidth, thumbnailHeight);
      const thumbnailDataUrl = canvas.toDataURL('image/jpeg');
      setThumbnail(thumbnailDataUrl);
    };
    img.src = imageUrl;
  }, [imageUrl])
  return (
    <Box sx={{ maxWidth: 300, maxHeight: 300 }}>
      <ButtonBase onClick={(event) => { handleCardClick(event) }}>
        <CardMedia
          className='p-4 object-contain lg:h-[200px]'
          component="img"
          // height="180"
          image={thumbnail}
          alt={header}
        />
        {/* <img src={thumbnail} /> */}
      </ButtonBase>
      <div>
        <p className='text-lg text-center'>{header}</p>
      </div>

    </Box>
  );
}
