import React, { useEffect, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ActionAreaCard from './ActionAreaCard';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import SceneHandler from '../utils/SceneHandler';
import { catalogue } from '../utils/Catalogue';
import Card from '@mui/material/Card';
import { CardMedia, CardContent, Button, Input } from '@mui/material';
import fileUploadImage from '../Assests/images/upload_image.png';

export let ActiveTypeGlobal;
// import VisuallyHiddenInput from '@mui/material/';
let sceneHandler = null;
const getUrlsForFabric = (name) => {
  const fabric = catalogue.fabrics.find(fabric => fabric.name === name);
  return fabric ? fabric.fabricimgURL : null;
};

export default function PopoverComponentFabrics({ anchorEl, onClose, data, scene, isOpen, sendDataToParent, loadedMeshNamesList }) {

  const open = isOpen; //Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [cardDataList, setCardDataList] = React.useState(catalogue.fabrics);
  const [selectedFile, setSelectedFile] = useState(null);
  const [filteredFabricList, setFilteredFabricList] = useState(catalogue.fabrics);
  const [query, setQuery] = React.useState('');
  const [activeTab, setActiveTab] = React.useState(null);
  const [prevMeshNameList, setPrevMeshNameList] = React.useState([]);
  const fileInputRef = React.createRef();

  useEffect(() => {
    setFilteredFabricList(cardDataList);
  }, [cardDataList])

  const handleFileChange = (event) => {
    const userImage = event.target.files[0];
    if (userImage) {
      if (sceneHandler) {
        const meshName = activeTab;
        const userImageURL = URL.createObjectURL(userImage);
        sceneHandler.fabricChange(userImageURL, meshName);
      }
    }
    setSelectedFile(userImage);
  }
  const handleSearchInputChange = (event) => {
    const searchQuery = event.target.value;
    setQuery(searchQuery);
    let dataFiltered = cardDataList.filter((data) => {
      return data.name.toLowerCase().includes(searchQuery.toLowerCase());
    })
    if (!searchQuery) {
      dataFiltered = cardDataList;
    }
    setFilteredFabricList(dataFiltered);
  }

  function handleCardClickHandler(event) {
    const fabricURL = getUrlsForFabric(event.target.alt)
    if (sceneHandler) {
      const meshName = activeTab;
      sceneHandler.fabricChange(fabricURL, meshName);
      setSelectedFile(null);
    }
  }

  React.useEffect(() => {
    sceneHandler = new SceneHandler(scene)
  }, [])

  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
  }));

  const handleClick = () => {
    // Trigger the file input click
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
 ActiveTypeGlobal = newValue;

    sendDataToParent({ "selectedFabricTab": newValue })
  };
  const getClothMeshNames = () => {
    let meshNames = [];
    let clothMeshGroup = scene.world.getObjectByName("CLOTH_MODEL_GROUP");
    if (clothMeshGroup) {
      clothMeshGroup.children.map((child, index) => {
        if ((!activeTab) && (index === 0)) {
          setActiveTab(child.name);
          ActiveTypeGlobal = child.name;
          setPrevMeshNameList(meshNames);
          sendDataToParent({ "selectedFabricTab": child.name })
        }
        meshNames.push(child.name);
      });
      if (activeTab && prevMeshNameList.length > 0 && (!arraysEqual(meshNames, prevMeshNameList))) {

        if (prevMeshNameList.length > 0) {
          setActiveTab(meshNames[0]);
          ActiveTypeGlobal = meshNames[0];
          setPrevMeshNameList(meshNames);
          sendDataToParent({ "selectedFabricTab": meshNames[0] })
        }
      }
    }
    return meshNames;
  }
  const arraysEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }
  return (
    <div className='p-4 absolute bottom-0 right-0'>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Search className='my-4'>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ 'aria-label': 'search' }}
            value={query}
            autoFocus={true}
            onChange={handleSearchInputChange}
          />
        </Search>
        <z
          value={activeTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {
            getClothMeshNames().map((child) => {
              return <Tab label={child} value={child} />
            })
          }

        </z>
        <Box className="p-4" style={{ display: "flex" }}>
          <Card sx={{ maxWidth: 150 }}>
            {/* <CardMedia */}
            {/*   component="img" */}
            {/*   height="140" */}
            {/*   image={fileUploadImage} */}
            {/*   alt={"Upload Fabric"} */}
            {/*   onClick={handleClick} */}
            {/*   style={{ cursor: 'pointer' }} */}
            {/* /> */}
            {/* <CardContent> */}
            {/*   <Button onClick={handleClick} variant="contained" color="primary"> */}
            {/*     Upload Fabric */}
            {/*   </Button> */}
            {/*   <Input */}
            {/*     type="file" */}
            {/*     accept="image/*" */}
            {/*     style={{ display: 'none' }} */}
            {/*     onChange={handleFileChange} */}
            {/*     onClick={(e) => (e.target.value = null)} // Reset input value to allow selecting the same file */}
            {/*     inputRef={fileInputRef} // Use inputRef instead of ref */}
            {/*   /> */}
            {/**/}
            {/*   {selectedFile && ( */}
            {/*     <div> */}
            {/*       <p>Selected File: {selectedFile.name}</p> */}
            {/*     </div> */}
            {/*   )} */}
            {/* </CardContent> */}

          </Card>

          <div className='grid grid-cols-2 lg:grid-cols-3'>
            {filteredFabricList.map((data) => {
              return <ActionAreaCard header={data.name} imageUrl={data.fabricimgURL} handleCardClickHandler={handleCardClickHandler}></ActionAreaCard>
            })}
          </div>
        </Box>
      </Popover>
    </div>
  );
}
